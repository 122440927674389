@import url("./themes.css");
@import url("./announcement-md.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    height: -webkit-fill-available;
  }

  body {
    height: -webkit-fill-available;
  }

  #root,
  #__next {
    height: -webkit-fill-available;
  }
}
