.message.markdown {
  white-space: pre-wrap;
}
.message.markdown table thead {
  display: none;
}
.message.markdown h1,
.ql-snow .ql-editor h1,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 1.5rem !important;
  line-height: 120%;
  text-transform: uppercase;
  @apply font-druk-wide;
  @apply mb-6;
}
.message.markdown h2,
.message.markdown h3,
.message.markdown h4,
.ql-snow .ql-editor h2,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.25rem !important;
  line-height: 120%;
  @apply font-bold;
  @apply mb-2;
}
.message.markdown a,
.ql-snow .ql-editor a {
  @apply text-[#A799FC] underline;
}
.message.markdown p,
.message.markdown ul,
.message.markdown p,
.message.markdown ol,
.ql-snow .ql-editor p,
.ql-snow .ql-editor ul,
.ql-snow .ql-editor ol {
  font-size: 1rem !important;
  line-height: 120%;
}
.message.markdown p:not(:last-child):not(:has(+ ul)):not(:has(+ ol)),
.message.markdown table:not(:last-child):not(:has(+ ul)):not(:has(+ ol)),
.message.markdown blockquote:not(:last-child):not(:has(+ ul)):not(:has(+ ol)),
.ql-snow .ql-editor p:not(:last-child):not(:has(+ ul)):not(:has(+ ol)) {
  @apply mb-6;
}
.message.markdown ol:not(:last-child),
.ql-snow .ql-editor ol:not(:last-child) {
  @apply mb-6;
}
.message.markdown ul:not(:last-child),
.ql-snow .ql-editor ul:not(:last-child) {
  @apply mb-6;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  font-size: 1rem !important;
  line-height: 120%;
  /* font-family: SFPro-Display-Regular; */
}

.message.markdown strong,
.ql-snow .ql-editor strong {
  @apply font-bold;
}

.message.markdown blockquote {
  line-height: 1px;
}
.ql-snow .ql-editor blockquote,
.message.markdown blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.message.markdown ol {
  list-style: auto !important;
  padding-left: 1em;
}
.message.markdown table tr {
  @apply flex gap-2 mb-2;
}
.message.markdown ul {
  list-style: disc !important;
  padding-left: 1em;
}
.ql-snow .ql-editor ol {
  list-style: auto !important;
  padding-left: 0;
}
.ql-snow .ql-editor ul {
  list-style: disc !important;
  padding-left: 0;
}
.ql-snow .ql-editor li,
.message.markdown li {
  padding-left: 0.5em;
}
