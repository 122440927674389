/* #react-auto-scroll__scroll_container *,
#chat_scroll_container * {
  overflow-anchor: none;
} */

textarea#mentions-input::-webkit-scrollbar,
.mentions__highlighter::-webkit-scrollbar {
  display: none;
}

.mentions {
  @apply w-full;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 160px;
}

.mentions__input:focus {
  @apply outline-none ring-0;
}

.mentions__control,
.mentions__input {
  @apply border border-neutral-700 bg-secondary rounded-2xl;
}

.mentions--multiLine .mentions__highlighter {
  min-height: 32px;
  @apply border bg-background border-neutral-500;
}

.mentions__suggestions__list {
  font-size: 10pt;
  max-height: 400px;
  overflow: scroll;
  @apply bg-background border border-neutral-700 text-foreground;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  @apply text-black bg-primary;
}

.emoji-mart-scroll {
  overscroll-behavior: none;
}

.mentions__reaction {
  position: relative;
  z-index: 1;

  @apply rounded text-cobalt;
}

.mentions__mention {
  pointer-events: none;
  position: relative;
  z-index: 1;
  left: -1px;
  @apply rounded p-1 bg-primary text-black font-medium text-sm;
}

.mentions__highlighter,
.mentions__input {
  overflow-y: scroll !important;
  max-height: 30vh;
  margin: 0 !important;

  @apply w-full pl-3 pr-8 lg:px-6 py-1 lg:py-2 lg:pt-3 lg:pr-20 mt-auto text-foreground shadow-sm placeholder-cobalt-50 rounded-2xl;
}
